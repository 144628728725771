<template>
  <div class="be-wrapper">
    <dashboard-navbar />
    <div class="be-left-sidebar">
      <div class="left-sidebar-wrapper">
        <div class="left-sidebar-spacer">
          <div class="left-sidebar-scroll">
            <div class="left-sidebar-content">
              <side-nav />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="be-content">
      <div class="main-content container-fluid">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import {FadeTransition} from "vue2-transitions";
import SideNav from '../components/SideNav.vue';

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
    SideNav,
  },
  // data() {
  //   return {
  //     sidebarBackground: "vue", //vue|blue|orange|green|red|primary
  //     country: "",
  //     mainCountry: process.env.VUE_APP_COUNTRY
  //       ? process.env.VUE_APP_COUNTRY
  //       : "Rwanda",
  //     userPermissions: [],
  //     plan: "",
  //   };
  // },
  // mounted() {
  //   this.country = localStorage.getItem("country");
  //   // set list of users permissions
  //   this.userPermissions = JSON.parse(
  //     localStorage.getItem("user")
  //   ).permissions.map((permission) => permission.slug);
  // },
  // methods: {
  //   toggleSidebar() {
  //     if (this.$sidebar.showSidebar) {
  //       this.$sidebar.displaySidebar(false);
  //     }
  //   },
  // },
};
</script>
<style></style>
