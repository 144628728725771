import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from './auth'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth
  },
  plugins: [createPersistedState()],
  getters: {
    // isAuthenticated: state => state.auth.token || false,
    getBreadcrumb: state => state.auth.breadcrumb_details,
    getUserDetails: state => state.auth.userDetails
  }
})