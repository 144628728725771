<template>
  <nav class="navbar navbar-expand fixed-top be-top-header">
    <div class="container-fluid">
      <div class="be-navbar-header">
        <router-link :to="{ name: 'dashboard' }" tag="a" class="navbar-brand">
          <div>
            <img src="../assets/logo.png" alt="logo" class="w-100" />
          </div>
        </router-link>
      </div>
      <div class="page-title pl-5">
        <span>Ared Admin Dashboard</span>
      </div>
      <div class="be-right-navbar py-5">
        <ul class="nav navbar-nav float-right be-user-nav align-items-center">
          <li class="nav-item">{{ currentDate }}</li>
          <li class="nav-item mx-3">|</li>
          <li class="nav-item">
            <span class="avatar avatar-sm rounded-circle">
              <i class="icon mdi mdi-account-box"></i>
            </span>
            <span class="mb-0 text-sm text-dark">
              {{ loggedInUser.first_name }}
            </span>
          </li>
          <li class="nav-item ml-3">|</li>
          <li class="nav-item">
            <div class="media-body  d-none d-lg-block cursor-pointer">
              <b-button
                class="mx-1 text-white"
                variant="success"
                size="sm"
                v-b-modal.translate-modal
              >
                Translate
              </b-button>
            </div>
          </li>
          <li class="nav-item">
            <div class="media-body  d-none d-lg-block cursor-pointer">
              <a @click="logout()" class="dropdown-item" role="button">
                <i class="icon mdi mdi-lock"></i>
                <span> Logout </span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <translate-modal></translate-modal>
  </nav>
</template>

<script>
// import LogOutWarning from "@/components/Auth/LogOutWarning";
import { mapGetters, mapMutations } from "vuex";
import TranslateModal from '../components/TranslateModal.vue';
import router from "../router";
export default {
  components: { TranslateModal },
  // components: {
  //   LogOutWarning,
  // },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
      currentDate: new Date().toLocaleString(),
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated", "loggedInUser"]),
  },
  mounted() {
    setInterval(() => {
      this.currentDate = new Date().toLocaleString();
    });
  },
  methods: {
    ...mapMutations(["clearUserData"]),
    logout() {
      this.clearUserData();
      router.push({ name: "login" });
    },
  },
};
</script>
