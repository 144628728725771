import axios from "axios";
import router from "../../router";
import Vue from "vue";

export default {
  state: {
    refresh_token: "",
    access_token: "",
    loggedInUser: {},
    isAuthenticated: false,
    loginBtn: {
      text: "Log in",
      disabled: false,
    },
  },
  mutations: {
    setLoginBtn(state, payload) {
      state.loginBtn.text = payload.text;
      state.loginBtn.disabled = payload.text !== "Log In" ? true : false;
    },
    setRefreshToken: function (state, refreshToken) {
      state.refresh_token = refreshToken;
    },
    setAccessToken: function (state, accessToken) {
      state.access_token = accessToken;
    },
    setLoggedInUser: function (state, user) {
      state.loggedInUser = user;
    },
    setAuthenticated: function (state) {
      state.isAuthenticated = true;
    },
    clearUserData: function (state) {
      state.refresh_token = "";
      state.access_token = "";
      state.loggedInUser = {};
      state.isAuthenticated = false;
    },
  },
  actions: {
    logIn: async ({ commit, dispatch }, payload) => {
      commit("setLoginBtn", { text: "Logging In..." });
      const loginUrl = "v1/auth/jwt/create/";
      try {
        await axios.post(loginUrl, payload).then((response) => {
          if (response.status === 200) {
            commit("setRefreshToken", response.data.refresh);
            commit("setAccessToken", response.data.access);
            dispatch("fetchUser", "createTwoFaToken");
          }
        });
      } catch (e) {
        commit("setLoginBtn", { text: "Log In" });
        if (e.response.data.detail) {
          console.log("ERROR: ", e.response.data.detail);
          Vue.swal("Error", `${e.response.data.detail}`, "error");
        }
        return false;
      }
    },
    refreshToken: async ({ state, commit }) => {
      const refreshUrl = "v1/auth/jwt/refresh/";
      try {
        await axios
          .post(refreshUrl, { refresh: state.refresh_token })
          .then((response) => {
            if (response.status === 200) {
              commit("setAccessToken", response.data.access);
            }
          });
      } catch (e) {
        console.log(e.response);
      }
    },
    createTwoFaToken: async ({ state, commit }) => {
      const allowedRoles = ["ared_admin", "ared_staff"];
      if (allowedRoles.includes(state.loggedInUser.role)) {
        try {
          commit("setLoginBtn", { text: "Sending OTP Token to your email..." });
          await axios.post("v1/2fa/").then((response) => {
            if (response.status === 200) {
              router.push({ name: "2fa" });
              Vue.swal(
                "",
                "Login Successful. OTP sent to your email inbox.",
                "success"
              );
            }
            commit("setLoginBtn", { text: "Log In" });
          });
        } catch (e) {
          console.log(e);
          commit("setLoginBtn", { text: "Log In" });
        }
      } else {
        let errorMessage = "";

        switch (state.loggedInUser.role) {
          case "Manufacturer":
            errorMessage =
              "Your credentials are for accessing the Devices dashboard. Accessing the Admin dashboard is restricted for your account type.";
            break;
          case "partner_admin":
          case "partner_staff":
            errorMessage =
              "Your credentials are for accessing the Partner dashboard. Accessing the Admin dashboard is restricted for your account type.";
            break;
          case "sme_admin":
          case "sme_staff":
            errorMessage =
              "Your credentials are for accessing the SME dashboard. Accessing the Admin dashboard is restricted for your account type.";
            break;
          default:
            errorMessage =
              "The provided credentials are not supposed to be used for this dashboard.";
            break;
        }

        Vue.swal("", errorMessage, "info");

        commit("clearUserData");
        commit("setLoginBtn", { text: "Log In" });
      }
    },
    verifyTwoFaToken: async ({ commit }, payload) => {
      try {
        await axios.post("v1/2fa/verify/", payload).then((response) => {
          if (response.status === 204) {
            commit("setAuthenticated");
            window.location.href = "dashboard";
            Vue.swal("", "Successfully logged in.", "success");
          }
        });
      } catch (e) {
        Vue.swal("", "Incorrect or expired OTP.", "error");
        console.log(e);
        return false;
      }
    },
    fetchUser: async ({ commit, dispatch }, payload) => {
      const currentUserUrl = "v1/auth/users/me/";
      try {
        commit("setLoginBtn", { text: "Fetching user info..." });
        await axios.get(currentUserUrl).then((response) => {
          if (response.status === 200) {
            commit("setLoggedInUser", response.data);
            if (payload) {
              dispatch("createTwoFaToken");
            }
          }
        });
      } catch (e) {
        console.log(e.response);
      }
    },
  },
  getters: {
    loggedInUser: (state) => state.loggedInUser,
    isAuthenticated: (state) => state.isAuthenticated,
    accessToken: (state) => state.access_token,
    refreshToken: (state) => state.refresh_token,
    loginBtn: (state) => state.loginBtn,
  },
};
