// import { createApp } from 'vue';
import Vue from "vue";
import App from "./App.vue";
import BootstrapVue from "bootstrap-vue";
import IdleVue from "idle-vue";
import router from "./router";
import store from "./store";
import VeeValidate from "vee-validate";
import VueHtmlToPaper from "vue-html-to-paper";
import VueSweetalert2 from "vue-sweetalert2";
import VueTelInput from "vue-tel-input";
import VueGoodTablePlugin from "vue-good-table";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import TableSkeleton from "./components/BaseComponents/TableSkeleton";

import Filters from "./helpers/filters";
import axiosSetup from "./helpers/interceptors";
import dotenv from "dotenv";

dotenv.config();

axiosSetup();

// global data table css
import "vue-good-table/dist/vue-good-table.css";
// global sweetalert css
import "sweetalert2/dist/sweetalert2.min.css";

import "./assets/css/style.css";

const htmlToPaperOptions = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
};

const sweetAlertOptions = {
  confirmButtonColor: "#348f6a",
  cancelButtonColor: "#ff7674",
};

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(VeeValidate);
Vue.use(VueSweetalert2, sweetAlertOptions);
Vue.use(VueTelInput);
Vue.use(VueGoodTablePlugin);
Vue.use(VueHtmlToPaper, htmlToPaperOptions);
Vue.use(require("vue-moment"));
Vue.component(VueQrcode.name, VueQrcode);
Vue.component("table-skeleton", TableSkeleton);
// apply a global filter
Vue.mixin({
  filters: Filters,
});

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 1000 * 60 * 10, // 10 minutes,
  startAtIdle: false,
});

// axios.defaults.baseURL = "https://api.shirikihub.com/api/"; // staging server
// axios.defaults.baseURL = 'http://127.0.0.1:8000/api/' //development server

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
