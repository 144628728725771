<template>
  <div>
    <vcl-table :columns="columns" :rows="rows"> </vcl-table>
  </div>
</template>

<script>
import { VclTable } from "vue-content-loading";

export default {
  name: "TableSkeleton",
  components: { VclTable },
  props: {
    rows: {
      type: Number,
      default: 4,
    },
    columns: {
      type: Number,
      default: 4,
    },
  },
};
</script>

<style>
</style>
