export default {
  planNameDisplay: function(value) {
    if (value == "SME") {
      return "Router Management";
    } else if (value == "Kiosk") {
      return "Kiosk Management";
    } else if (value == "Premium") {
      return "Premium Plan";
    }
  },
};
