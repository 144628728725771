var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"sidebar-elements"},[_c('li',{staticClass:"divider"},[_vm._v("Menu")]),_c('li',{},[_c('router-link',{attrs:{"to":{ name: 'dashboard' }}},[_c('span',[_c('i',{staticClass:"icon mdi mdi-home"}),_c('span',[_vm._v("Dashboard")])])])],1),_c('li',{},[_c('router-link',{attrs:{"to":{ name: 'partners' }}},[_c('span',[_c('i',{staticClass:"icon mdi mdi-case"}),_c('span',[_vm._v("Partners")])])])],1),_c('li',{},[_c('router-link',{attrs:{"to":{ name: 'kiosks' }}},[_c('span',[_c('i',{staticClass:"icon mdi mdi-store"}),_c('span',[_vm._v("Kiosks")])])])],1),_c('li',{},[_c('router-link',{attrs:{"to":{ name: 'routers' }}},[_c('span',[_c('i',{staticClass:"icon mdi mdi-router"}),_c('span',[_vm._v("Routers")])])])],1),_c('li',{},[_c('router-link',{attrs:{"to":{ name: 'plans' }}},[_c('span',[_c('i',{staticClass:"icon mdi mdi-money-box"}),_c('span',[_vm._v("Plans")])])])],1),_c('li',{},[_c('router-link',{attrs:{"to":{ name: 'apps' }}},[_c('span',[_c('i',{staticClass:"icon mdi mdi-smartphone"}),_c('span',[_vm._v("App Store")])])])],1),(
      _vm.loggedInUser.role === _vm.adminRole ||
      _vm.loggedInUser.user_type === _vm.customerSupportUserType
    )?_c('li',[_c('router-link',{attrs:{"to":{ name: 'support-tickets' }}},[_c('span',[_c('i',{staticClass:"icon mdi mdi-help"}),_c('span',[_vm._v("Support Tickets")])])])],1):_vm._e(),_c('li',{},[_c('router-link',{attrs:{"to":{ name: 'my-profile' }}},[_c('span',[_c('i',{staticClass:"icon mdi mdi-account-box"}),_c('span',[_vm._v("My Profile")])])])],1),(_vm.loggedInUser.role === _vm.adminRole)?_c('li',[_c('router-link',{attrs:{"to":{ name: 'users' }}},[_c('span',[_c('i',{staticClass:"icon mdi mdi-male-female"}),_c('span',[_vm._v("System Admins")])])])],1):_vm._e(),(
      _vm.loggedInUser.role === _vm.adminRole ||
      _vm.loggedInUser.role === _vm.manufacturerRole
    )?_c('li',[_c('router-link',{attrs:{"to":{ name: 'manufacturers-list' }}},[_c('span',[_c('i',{staticClass:"icon mdi mdi-settings"}),_c('span',[_vm._v("Manufacturers")])])])],1):_vm._e(),(
      _vm.loggedInUser.role === _vm.adminRole ||
      _vm.loggedInUser.user_type === _vm.customerSupportUserType
    )?_c('li',[_c('router-link',{attrs:{"to":{ name: 'customer-support-agents-list' }}},[_c('span',[_c('i',{staticClass:"icon mdi mdi-headset"}),_c('span',[_vm._v("Customer Support")])])])],1):_vm._e(),_c('li',{},[_c('router-link',{attrs:{"to":{ name: 'sales-representatives-list' }}},[_c('span',[_c('i',{staticClass:"icon mdi mdi-account"}),_c('span',[_vm._v("Sales Representatives")])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }