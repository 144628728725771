<template>
  <div id="app" class="be-wrapper be-login">
    <div class="be-content">
      <div class="main-content container-fluid">
        <slide-y-up-transition mode="out-in" origin="center top">
          <router-view></router-view>
        </slide-y-up-transition>
      </div>
    </div>
  </div>
</template>
<script>
import { SlideYUpTransition } from "vue2-transitions";

export default {
  name: "auth-layout",
  components: {
    SlideYUpTransition,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      showMenu: false,
    };
  },
  mounted() {
    window.$("body").addClass("be-splash-screen");
  }
};
</script>
